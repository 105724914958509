import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './home.css'

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <Helmet>
        <title>Terms of Service - GetRichQuick</title>
      </Helmet>
      <Navbar />
        
    <div id="app">
      <header align="center">
        <h1>GetRichQuick</h1>
        <p>Privacy Policy</p>
        </header><br /><br /><br />
    <div class="container" align="center">
        <article>
            <h2>1. Introduction</h2>
            <p>Welcome to GetRichQuiack. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website. By using our website, you consent to the practices described in this policy.</p>
            
            <h2>2. Information We Collect</h2>
            <p>We collect various types of information to provide and improve our services:</p>
            <ul>
                <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and other contact details you provide when you register on our site, make a purchase, or contact us.</li>
                <li><strong>Usage Data:</strong> This includes information on how you interact with our website, such as your IP address, browser type, pages visited, and the time spent on our site.</li>
                <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your user experience and gather data about your visit to our site. Cookies are small data files stored on your device.</li>
            </ul>
            
            <h2>3. How We Use Your Information</h2>
            <p>We use the collected information for the following purposes:</p>
            <ul>
                <li><strong>To Provide and Maintain Our Services:</strong> This includes processing transactions, responding to your inquiries, and improving our website functionality.</li>
                <li><strong>To Communicate with You:</strong> We may send you updates, newsletters, or promotional materials related to our services. You can opt out of these communications at any time.</li>
                <li><strong>To Analyze Usage:</strong> We use usage data to understand how our website is used and to improve our content and services.</li>
                <li><strong>To Personalize Your Experience:</strong> We may use your information to customize your experience on our website.</li>
            </ul>
            
            <h2>4. How We Share Your Information</h2>
            <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
            <ul>
                <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, conducting business, or servicing you, provided they agree to keep the information confidential.</li>
                <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to legal requests or processes.</li>
            </ul>
            
            <h2>5. Security of Your Information</h2>
            <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
            
            <h2>6. Your Choices</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>Access and Update:</strong> You can access and update your personal information by contacting us.</li>
                <li><strong>Opt-Out:</strong> You may opt out of receiving promotional emails from us by following the instructions in those emails or contacting us directly.</li>
                <li><strong>Cookies:</strong> You can set your browser to refuse cookies or to alert you when cookies are being sent. However, some parts of our site may not function properly without cookies.</li>
            </ul>
            
            <h2>7. Links to Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.</p>
            
            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website following any changes constitutes your acceptance of the new terms.</p>
            
            <h2>9. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or our practices, please contact us at contact@getrichquick.online</p>
        </article>
      </div>
      <Footer />
    </div>
    </div>
  )
}

export default TermsOfService