import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './home.css'

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <Helmet>
        <title>Terms of Service - GetRichQuick</title>
      </Helmet>
      <Navbar />
      <div className="content">
  
    <div id="app">
      <header align="center">
        <h1>GetRichQuick</h1>
        <p>Terms Of Use</p>
      </header>
      <br /><br /><br />
      <section id="services" align="center">
        <h2>Our Services</h2>
        <p>We offer a range of digital content marketing solutions, leveraging AI for automation of content generation pipelines and social website automations.</p>

        <article align="center">
            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use this website.</p>
            
            <h2>2. Changes to Terms</h2>
            <p>We reserve the right to modify these terms at any time. Changes will be posted on this page with an updated effective date. Your continued use of the website following any changes constitutes your acceptance of the new terms.</p>
            
            <h2>3. Use of Website</h2>
            <p>You agree to use this website only for lawful purposes and in a manner that does not infringe on the rights of, restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes, but is not limited to, harassment, causing distress, or interfering with the website’s functionality.</p>
            
            <h2>4. Intellectual Property</h2>
            <p>The content of this website, including text, images, graphics, and logos, is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without explicit permission.</p>
            
            <h2>5. User Content</h2>
            <p>Any content you submit to the website (e.g., comments, reviews) remains your property. However, by submitting content, you grant us a worldwide, royalty-free, perpetual, and non-exclusive license to use, reproduce, modify, and display such content.</p>
            
            <h2>6. Privacy Policy</h2>
            <p>Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using the website, you consent to the practices described in our Privacy Policy.</p>
            
            <h2>7. Disclaimers</h2>
            <p>The website and its content are provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation or availability of the website or the accuracy of the information provided.</p>
            
            <h2>8. Limitation of Liability</h2>
            <p>In no event shall we be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of the website. This includes any damages for loss of profits, data, or other intangible losses.</p>
            
            <h2>9. Links to Third-Party Sites</h2>
            <p>Our website may contain links to third-party websites. We are not responsible for the content or practices of these third-party sites. You should review their terms and conditions and privacy policies before using them.</p>
            
            <h2>10. Governing Law</h2>
            <p>These terms shall be governed by and construed in accordance with the laws of the state. Any disputes arising from these terms or your use of the website shall be subject to the exclusive jurisdiction of the courts of the state.</p>
            
            <h2>11. Contact Information</h2>
            <p>If you have any questions about these terms, please contact us at [Your Contact Information].</p>
            </article>
        </section>
    </div>


    </div>


      <Footer />
    </div>
  )
}

export default TermsOfService